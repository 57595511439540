import * as React from 'react'
import { useState, useEffect } from 'react'
import { Router } from '@reach/router'
import useUrlState from '../hooks/useUrlState'
import { BlogPostSectionRenderer } from '../section-renderer/blog-post'
import { RepairBlogSectionRenderer } from '../section-renderer/repair-blog'
import { RepairBlogCategorySectionRenderer } from '../section-renderer/repair-blog-category'
import CmsPageSectionRenderer from '../section-renderer/page'
import BlogPostRelData from '../section-renderer/blog-post-additional-data'
import PropTypes from 'prop-types'

const RenderData = ({ type, page, postRelData, firstSection, otherSections }) => {
  switch (type) {
    case 'blog-post': {
      return (
        <BlogPostSectionRenderer
          breadcrumbs={[{ url: '/blog/', name: 'Blog' }]}
          products={postRelData.products}
          recommended={postRelData.recommended}
          firstSection={firstSection}
          otherSections={otherSections}
          page={page.page.attributes}
        />
      )
    }
    case 'repair-blog-post': {
      let data = {
        page: {
          brand: {
            brand: page.page.attributes.brand.data.attributes.brand,
          },
          model: page.page.attributes.model,
          sections: page.page.attributes.sections,
          safeHeroImage: page.page.attributes.safeHeroImage,
          url: page.page.attributes.url,
        },
      }
      return <RepairBlogSectionRenderer data={data} />
    }
    case 'repair-blog-category': {
      let data = {
        page: {
          brand: page.page.attributes.brand,
          model: page.page.attributes.model,
          sections: page.page.attributes.sections,
          safeHeroImage: page.page.attributes.safeHeroImage,
          url: page.page.attributes.url,
          models: page.page.attributes.models,
        },
      }
      return <RepairBlogCategorySectionRenderer data={data} />
    }
    case 'page': {
      let data = {
        page: {
          title: page.page.attributes.title,
          content: page.page.attributes.content,
          sections: page.page.attributes.sections,
          publishedAt: page.page.attributes.publishedAt,
          updatedAt: page.page.attributes.updatedAt,
        },
      }
      return <CmsPageSectionRenderer data={data} />
    }
    default:
      return <div className="whitespace-pre">{JSON.stringify(page, null, 2)}</div>
  }
}
RenderData.propTypes = {
  type: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  postRelData: PropTypes.object.isRequired,
  firstSection: PropTypes.object,
  otherSections: PropTypes.array,
}
const Details = () => {
  const [slug] = useUrlState('slug')
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const _page = {
    meta_title: 'Preview',
    slug: slug || 'preview',
    meta_description: 'Preview',
    title: 'Preview',
  }
  const [page, setPage] = useState(_page)

  useEffect(() => {
    if (!slug) return
    setIsLoading(true)
    fetch(`/api/get-page?slug=${encodeURIComponent(slug)}`)
      .then(res => res.json())
      .then(
        data => {
          console.log('data', data)
          setPage(data)
          setIsError(false)
          setIsLoading(false)
        },
        err => {
          console.log('err', err)
          setIsError(true)
          setIsLoading(false)
        }
      )
  }, [slug])

  const postRelData = BlogPostRelData()

  let firstSection
  let otherSections
  if (!isLoading) {
    const sections = page ? page?.page?.attributes?.sections : []
    firstSection = sections ? sections[0] : null
    otherSections = sections ? sections.slice(1) : null

    console.log('pageData', page)
  }

  return (
    <>
      {isError && <div className="container mx-auto">Error - Can't load page!!!</div>}
      {isLoading ? (
        <div className={'container mx-auto px-4'}>Loading {page.slug}...</div>
      ) : (
        <section>
          <RenderData
            postRelData={postRelData}
            type={page.contentType}
            page={page}
            firstSection={firstSection}
            otherSections={otherSections}
          />
        </section>
      )}
    </>
  )
}

const PreviewPage = () => {
  return (
    <Router basepath="/preview">
      <Details path="/:slug" />
      <Details path="/" />
    </Router>
  )
}

export default PreviewPage
